<template>
	<ValidationObserver
		class="auth-step__form"
		tag="form"
		ref="fourthStepForm"
		v-slot="{ invalid }"
		@submit.prevent="handleSubmitForm"
	>
		<h2 class="auth-form__title">Войти по электронной почте</h2>
		<UiValidatedInput
			rules="required|email"
			v-model="form.phone"
			id="auth-email"
			label="Электронная почта"
			type="email"
			name="email"
		/>
		<UiPasswordInput
			rules="required"
			v-model="form.password"
			id="auth-password"
			label="Пароль"
			name="password"
		/>
		<UiButton
			:disabled="invalid"
			:loading="loading"
		>
			Войти
		</UiButton>
		<UiButton
			@click.prevent="setAuthStep(1)"
			variant="text"
			size="small"
		>
			<ArrowBackIcon class="step-arrow__icon"/>
			Вернуться назад
		</UiButton>
	</ValidationObserver>
</template>
<script>
import {UiValidatedInput, UiButton, UiPasswordInput} from "ui-kit";
import {mapActions, mapMutations} from "vuex";
import {notification} from "@/utils";
import ArrowBackIcon from "icons/24/arrow-back.svg?inline";

export default {
	name: "FourthStep",
	components: {UiButton, UiValidatedInput, UiPasswordInput, ArrowBackIcon},
	data()
	{
		return {
			form: {
				phone: "",
				password: "",
			},
			loading: false
		}
	},
	methods:
		{
			...mapMutations({
				setAuthStep: "setAuthStep",
				setAuthFormPopup: "setAuthFormPopup",
				setUser: "setUser",
			}),
			...mapActions({
				authorization: "authorization",
				getCart: "getCart",
			}),
			async handleSubmitForm()
			{
				const isValid = await this.$refs.fourthStepForm.validate();
				if (!isValid) return;

				this.loading = true;

				try
				{
					const {data} = await this.authorization(this.form);

					if (!data.success)
					{
						const title = data.msg || "Неизвестная ошибка";
						return notification({title, type: "error"});
					}

					this.setUser(data.user);

					await this.getCart();

					this.$socket.init();

					await this.handleUserAuth();
				} catch (e)
				{
					throw new Error(e);
				} finally
				{
					this.loading = false;
				}
			},
			async handleUserAuth()
			{
				this.setAuthFormPopup(false);

				const userAgent = window.navigator.userAgent.match(/mobileApp/i);
				if (userAgent) return window.location.href = '/application/personal/';

				window.location.reload();
			}
		}
}
</script>